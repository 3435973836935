<template>
  <div>
    <div class="d-flex justify-content-end mt-0" />
    <b-card
      no-body
    >
      <div
        class="m-2"
      >
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="6"
            sm="12"
            offset-sm="0"
            offset-md="6"
            offset-lg="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @keyup.enter="pageChanged(1)"
              />
              <b-button
                variant="primary"
                @click="pageChanged(1)"
              >
                Search
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-skeleton-table
        v-if="spinner"
        :rows="paginationObject.limit"
        :columns="5"
        :table-props="{ bordered: true, striped: true, tableClass:'skeleton-loading-table' }"
      />
      <b-table
        v-else
        id="allGroups-table"
        hover
        selectable
        :per-page="paginationObject.limit"
        :select-mode="selectMode"
        :items="allGroups"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
        :sort-by.sync="sortBy"
        @row-selected="onRowSelected"
      >
        <!-- Column: id -->
        <template #cell(ID)="data">
          <span class="text-nowrap">
            {{ data.item.id }}
          </span>
        </template>
        <!-- Column: User -->
        <template
          #cell(GROUP_NAME)="data"
        >
          <b-media vertical-align="center">
            <span
              :to="{ name: 'user', params: { id: data.item.id } }"
              class="text-nowrap d-inline-block text-truncate"
              style="max-width: 120px;"
            >
              {{ data.item.user_has_group.name }}
            </span>
          </b-media>
        </template>

        <!-- Column: Email -->
        <template #cell(SCREEN_NAME)="data">
          <span class="text-nowrap">
            {{ data.item.name }}
          </span>
        </template>

        <!-- Column: Status -->
        <template
          #cell(Screen_Venue)="data"
        >
          {{ data.item.venue.venue_name }}
        </template>
        <!--Column: Status-->
        <template #cell(Status)="data">
          <div class="text-nowrap">
            <span>
              <b-badge
                class="badge"
                :variant="data.item.status === DeviceStatus.ACTIVE ? 'light-success' : 'light-danger'"
              >{{
                data.item.status === DeviceStatus.ACTIVE ? DeviceStatus.ACTIVE : DeviceStatus.INACTIVE }}
              </b-badge>
            </span>
          </div>
        </template>
        <template
          #cell(Actions)="data"
          class="d-flex flex-row justify-content-around"
        >
          <span
            class="no-wrap"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-danger"
              size="sm"
              class="btn-icon rounded-circle m-0"
              @click="deleteScreen(data)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>
        </template>
      </b-table>
    </b-card>
    <CustomPagination
      v-if="allGroups.length"
      :total="paginationObject.total"
      :per-page="paginationObject.limit"
      :page="paginationObject.offset"
      @perPageChanged="perPageChanged"
      @pageChanged="pageChanged"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  VBToggle,
  BSkeletonTable,
  BBadge,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import ActiveType from '@/common/enums/accountStatusEnum'
import CustomPagination from '@/components/common/CustomPagination.vue'
import { showToast } from '@/common/global/functions'
import DeviceStatus from '@/common/enums/deviceStatusEnum'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    CustomPagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BSkeletonTable,
    BBadge,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      DeviceStatus,
      ActiveType,
      active: false,
      sortBy: 'STATUS',
      searchQuery: '',
      spinner: false,
      allGroups: [],
      deleteUserId: null,
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
      },
      tableColumns: [
        { key: 'ID', label: 'SCREEN ID' },
        { key: 'GROUP_NAME', label: 'BUSINESSES NAME' },
        { key: 'SCREEN_NAME', label: 'SCREEN NAME' },
        { key: 'Screen_Venue', label: 'Screen Venue' },
        { key: 'Status', label: 'Status' },
        // { key: 'Actions', thClass: 'text-center', tdClass: 'text-center' },
      ],
      selectMode: 'single',
      selected: [],
    }
  },
  async mounted() {
    await this.getAllScreens(this.paginationObject)
  },
  methods: {
    OpenEditBusinessModal(event) {
      this.$refs['create-group-modal'].show(event.item)
    },
    async deleteScreen(event) {
      this.$swal.fire({
        title: 'Are you sure you want to delete this screen?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        cancelButtonText: ' Cancel',
        reverseButtons: true,
        width: '650px',
      }).then(async result => {
        if (result.isConfirmed) {
          this.spinner = true
          try {
            const success = await this.$store.dispatch('devices/deleteDeviceByAdmin', { macAddress: event.item.mac_address })
            if (success) {
              showToast('Device Deleted', 'Device deleted successfully', 'success')
              await this.getAllScreens(this.paginationObject)
            }
          } catch (e) {
            const errorMessage = e.response && e.response.data && e.response.data.message
              ? e.response.data.message
              : e.message
            showToast('Device Deletion', errorMessage, 'danger')
          } finally {
            this.spinner = false
          }
        }
      })
    },
    async refreshData() {
      await this.getAllScreens(this.paginationObject)
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllScreens(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllScreens(this.paginationObject)
    },
    getAllScreens({ offset, limit }) {
      this.spinner = true
      this.$axios.get(`admin/device/all-screens?offset=${offset - 1}&limit=${limit}&search_query=${this.searchQuery}`)
        .then(({ data }) => {
          this.allGroups = data.data.devices
          this.paginationObject.total = data.data.total
          this.spinner = false
        }).catch(() => { this.$swal('Businesses not found!') })
    },
    onRowSelected(group) {
      this.selected = group
      this.$router.push({
        name: 'single-device-admin-allScreens',
        params: {
          deviceId: this.selected[0].id.toString(),
          userId: this.selected[0].user_has_group.id,
          deviceName: this.selected[0].name,
          venueId: this.selected[0].id.toString(),
        },
      })
    },
    changeState() {
      this.active = false
    },
    resolveUserStatusVariant(Status) {
      if (Status === ActiveType.ACTIVE) return 'success'
      if (Status === ActiveType.INACTIVE) return 'secondary'
      return 'primary'
    },
    iconFunc(Status) {
      /* eslint-disable global-require */
      if (Status === ActiveType.ACTIVE) return require('@/assets/images/simiicons/Grey.svg')
      if (Status === ActiveType.INACTIVE) return require('@/assets/images/simiicons/Grey.svg')
      return require('@/assets/images/simiicons/Grey.svg')
    },
    deleteModal(e) {
      e.preventDefault()
      e.stopPropagation()
    },
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  .circular_image {
    border-radius: 40px;
    background-color: #eeedfd;
  }
  </style>
